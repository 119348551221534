import { ShimmerBox } from '@screen/styles/js/ShimmerBox';

/* <div className="w-full">
      <div className="mx-auto max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
        <NextImage
          src="/images/dev/meta.png"
          width="1280"
          height="720"
          className="rounded-3xl"
          objectFit="cover"
        />

        <p className="font-medium text-center text-3xl">
          Lorem Ipsum is simply dummy text of the printing and typesetting
        </p>

        <span className="mx-auto block my-6 h-px bg-floor-green w-2/5" />

        <p className="font-medium text-center text-xl text-typography-grey">
          Lorem Ipsum is simply dummy text of the printing and typesetting
        </p>

        <Button className="block mt-8 mx-auto uppercase text-typography-white">
          Leia mais
        </Button>
      </div>
    </div> */

export const PostOnLoad = (): JSX.Element => (
  <div className="w-full p-4">
    <div className="mx-auto max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
      <ShimmerBox className="w-full h-56 rounded-3xl" />
      <ShimmerBox className="mt-4 h-16 w-full rounded-md" />

      <span className="mx-auto block my-6 h-px bg-floor-green w-2/5" />

      <ShimmerBox className="h-8 mx-auto w-4/5 rounded-md" />
      <ShimmerBox
        className="mt-8 mx-auto w-56 rounded-full"
        style={{
          height: 'var(--wai-min-height)',
        }}
      />
    </div>
  </div>
);
