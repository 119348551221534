import { RevealOnScroll } from '@screen/components/effect/RevealOnScroll';
import { TestimonialsSlider } from '@screen/components/prismic/TestimonialsSlider';

export const Testimonials = (): JSX.Element => (
  <RevealOnScroll asProp="section" className="py-20">
    <div className="bowl-content">
      <h1 className="text-center font-bold text-5xl">
        O que nossos clientes acham.
      </h1>
    </div>

    <div className="mt-20">
      <TestimonialsSlider />
    </div>
  </RevealOnScroll>
);
