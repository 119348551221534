import NextImage from 'next/image';
import CountUp from 'react-countup';

import Car from '@means/assets/png/car.png';
import Device from '@means/assets/png/device.png';
import Apple from '@means/assets/svg/apple.svg';
import GooglePlay from '@means/assets/svg/google-play.svg';
import { APP_APPSTORE_URL, APP_PLAYSTORE_URL } from '@means/data/constants';
import { RevealOnScroll } from '@screen/components/effect/RevealOnScroll';
import { Button } from '@screen/components/ui/Button';

import styles from './styles.module.css';

export const Main = (): JSX.Element => (
  <section>
    <div className={styles.main}>
      <div className="pt-5 relative md:py-2">
        <div className="bowl-content">
          <div className="md:bowl-content-header">
            <h1 className="w-max flex flex-col font-bold text-7xl pb-24 md:text-9xl md:pt-14">
              Orgulho
              <span className="ml-auto text-2xl md:text-3xl">
                de estar aqui
              </span>
            </h1>

            <div className="max-w-lg ml-auto absolute z-10 -mt-28 md:absolute md:-bottom-96 md:max-w-7xl md:-right-10">
              <NextImage src={Car} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className={styles['grow-green']} />

      <div className={styles['down-wrapper']}>
        <div className="bowl-content">
          <RevealOnScroll className="flex items-center justify-center flex-col md:flex-row">
            <div className="max-w-md order-2 md:order-1 md:my-15">
              <div className={styles.device}>
                <NextImage src={Device} />
              </div>
            </div>

            <div className="text-typography-white order-1 mt-14 md:-mt-24 md:ml-28">
              <h1 className="font-bold text-5xl">Baixe já o nosso app!</h1>
              <p className="max-w-2xl text-2xl mt-2 text-typography-green">
                Peça uma viagem de maneira rápida, simples e segura.
              </p>

              <section className="flex gap-10 mt-16 flex-wrap max-w-max">
                <Button
                  asProp="a"
                  target="_blank"
                  className="flex items-center gap-6 w-auto flex-grow justify-center"
                  href={APP_APPSTORE_URL}
                >
                  <Apple className="w-4" /> App Store
                </Button>

                <Button
                  asProp="a"
                  target="_blank"
                  className="flex items-center gap-6 w-auto flex-grow justify-center"
                  href={APP_PLAYSTORE_URL}
                >
                  <GooglePlay className="w-4" /> Google Play
                </Button>
              </section>
            </div>
          </RevealOnScroll>
        </div>
      </div>

      <div className={styles['down-wrapper']}>
        <div className="bowl-content">
          <RevealOnScroll className="flex items-center justify-center flex-col md:flex-row ">
            <div className="flex flex-wrap items-center text-typography-white gap-10">
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-4xl font-bold md:text-9xl">
                  <CountUp start={0} end={16} delay={1.5} />
                </h1>
                <p className="uppercase font-semibold">Estados</p>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-4xl font-bold md:text-9xl">
                  <CountUp start={0} end={156} delay={1.5} />
                </h1>
                <p className="uppercase font-semibold">Cidades</p>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-4xl font-bold md:text-9xl">
                  <CountUp start={0} end={36} delay={1.5} />
                </h1>
                <p className="uppercase font-semibold">Franquias</p>
              </div>

              <div className="flex flex-col items-center">
                <h1 className="text-4xl font-bold md:text-9xl">
                  <CountUp start={0} end={2} suffix="M+" delay={1.5} />
                </h1>
                <p className="uppercase font-semibold">Usuários</p>
              </div>
            </div>
          </RevealOnScroll>
        </div>
      </div>
    </div>
  </section>
);
