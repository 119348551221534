// import { Delivery } from '@screen/atoms/home/Delivery';
import { DriveReward } from '@screen/atoms/home/DriveReward';
import { Main } from '@screen/atoms/home/Main';
import { MapWithBranches } from '@screen/atoms/home/MapWithBranches';
import { Testimonials } from '@screen/atoms/home/Testimonials';
import { SEO } from '@screen/components/forward/SEO';

const Home = (): JSX.Element => (
  <>
    <SEO />

    <div>
      <Main />
      <MapWithBranches />
      <DriveReward />
      {/* <Delivery /> */}
      <Testimonials />
    </div>
  </>
);

export default Home;
