import { ShimmerBox } from '@screen/styles/js/ShimmerBox';

export const TestimonialOnLoad = (): JSX.Element => (
  <div className="w-full p-4">
    <div className="mx-auto shadow-md p-14 rounded-3xl max-w-lg border-opacity-60  sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
      <div className="flex text-typography-white">
        <div className="flex p-1-5 rounded-full border-2 border-opacity-30 border-floor-green">
          <ShimmerBox className="animate-pulse flex-shrink-0 w-40 h-40 p-1 rounded-full" />
        </div>

        <div className="ml-12 mt-2 w-full">
          <ShimmerBox className="w-full max-w-xs h-8 rounded-md" />
          <ShimmerBox
            className="mt-2 w-full h-4 rounded-md"
            style={{
              maxWidth: '7rem',
            }}
          />
        </div>
      </div>

      <ShimmerBox className="w-full h-28 rounded-md mt-8" />
    </div>
  </div>
);
