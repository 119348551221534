import Car from '@means/assets/svg/car.svg';
// import Moto from '@means/assets/svg/moto.svg';
import { useUI } from '@modules/hooks/useUI';
import { RevealOnScroll } from '@screen/components/effect/RevealOnScroll';
import { PostsSlider } from '@screen/components/prismic/PostsSlider';
import { Button } from '@screen/components/ui/Button';

export const DriveReward = (): JSX.Element => {
  const { openDriverModal } = useUI();

  return (
    <RevealOnScroll asProp="section" className="bowl-content py-20">
      <h1 className="text-center font-bold text-6xl">Dirija e ganhe</h1>

      <div className="hide-scrollbar max-w-max w-full overflow-auto mt-16 mx-auto flex items-center gap-24 p-2 rounded-full bg-floor-grey">
        <div className="flex gap-6 items-center">
          <span className="flex p-2 items-center w-14 justify-center h-14 flex-shrink-0 rounded-full bg-floor-dark-500">
            <Car />
          </span>
          <span className="font-bold">Urbano Viagens</span>
        </div>

        {/* <div className="flex gap-6 items-center">
          <span className="flex items-center w-20 justify-center h-20 flex-shrink-0 rounded-full bg-floor-dark-500">
            <Moto />
          </span>
          <span>Urbano Entregas</span>
        </div> */}

        <Button
          onClick={openDriverModal}
          className="uppercase font-medium text-typography-white"
        >
          Quero ser motorista
        </Button>
      </div>

      <div className="mt-20">
        <PostsSlider />
      </div>
    </RevealOnScroll>
  );
};
