import type { TestimonialsData } from '@modules/api/types';
import type { Testimonial } from '@modules/prismic/types';

import { mergeRefs, mergeClassNames } from '@hitechline/reactools';
import axios from 'axios';
import NextImage from 'next/image';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import SwiperCore, { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import NavigationLeft from '@means/assets/svg/navigation-left.svg';
import NavigationRight from '@means/assets/svg/navigation-right.svg';

import styles from './styles.module.css';
import { TestimonialOnLoad } from './TestimonialOnLoad';

const SWIPER_OPTIONS: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 20,
  watchOverflow: true,
  resizeObserver: true,
  pagination: {
    dynamicBullets: true,
  },
};

export const TestimonialsSlider = (): JSX.Element => {
  const swiperRef = useRef<SwiperCore>(null);

  const [loading, updateLoading] = useState<boolean>(true);
  const [testimonials, updateTestimonials] = useState<Testimonial[]>([]);

  const [swiperDetails, updateSwiperDetails] = useState({
    canNext: false,
    canPrev: false,
  });

  const slides = useMemo(() => {
    if (loading || !testimonials.length) {
      return (
        <SwiperSlide>
          <TestimonialOnLoad />
        </SwiperSlide>
      );
    }

    return testimonials.map(({ id, picture, message, name, ocupation }) => (
      <SwiperSlide key={id}>
        <div className="w-full">
          <div className="mx-auto p-8 rounded-3xl bg-floor-dark-600 max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
            <div className="flex text-typography-white">
              <section className="flex flex-shrink-0 w-28 h-28 p-1-5 rounded-full border-2 border-floor-green">
                <NextImage
                  src={picture}
                  alt="Profile"
                  width="256"
                  height="256"
                  className="w-28 h-28 object-cover rounded-full"
                />
              </section>

              <div className="ml-6 mt-2">
                <h4 className="font-bold text-2xl">{name}</h4>
                <h5 className="text-base font-light">{ocupation}</h5>
              </div>
            </div>

            <p className="text-typography-grey mt-8">{message}</p>
          </div>
        </div>
      </SwiperSlide>
    ));
  }, [loading, testimonials]);

  const goNext = useCallback(() => {
    swiperRef.current?.slideNext();
  }, []);

  const goPrev = useCallback(() => {
    swiperRef.current?.slidePrev();
  }, []);

  const getTestimonials = async (): Promise<void> => {
    const { data } = await axios.get<TestimonialsData>('/api/testimonials');

    updateTestimonials(data);
    updateLoading(false);
    updateSwiper();
  };

  const updateSwiper = (): void => {
    if (!swiperRef.current) {
      return;
    }

    const { activeIndex, params, slides: swiperSlides } = swiperRef.current;
    const { length } = swiperSlides;
    const { slidesPerView } = params;

    const inIndex = activeIndex + (slidesPerView as number);

    updateSwiperDetails({
      canNext: inIndex < length,
      canPrev: activeIndex > 0,
    });
  };

  useEffect(() => {
    getTestimonials();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const currentSwiperRef = swiperRef.current;

    currentSwiperRef?.on('slideChange', updateSwiper);

    return () => {
      currentSwiperRef?.off('slideChange', updateSwiper);
    };
  }, []);

  return (
    <div className="items-center flex">
      <button
        type="button"
        className={styles['navigation-button']}
        onClick={goPrev}
        disabled={!swiperDetails.canPrev}
      >
        <NavigationLeft className="w-14" />
      </button>

      <Swiper
        onSwiper={mergeRefs([swiperRef])}
        {...SWIPER_OPTIONS}
        className="spacing-x lg:px-0"
      >
        {slides}
      </Swiper>

      <button
        type="button"
        onClick={goNext}
        disabled={!swiperDetails.canNext}
        className={mergeClassNames(styles['navigation-button'], 'right')}
      >
        <NavigationRight className="w-14" />
      </button>
    </div>
  );
};
