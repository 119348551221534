import { mergeClassNames } from '@hitechline/reactools';

import { makePolymorphicComponent } from '@screen/components/forward/makePolymorphicComponent';
import { BaseButton } from '@screen/components/ui/BaseButton';

export const Button = makePolymorphicComponent(
  'button',
  ({ PolymorphicElement, className, children, ...props }) => (
    <BaseButton
      {...props}
      asProp={PolymorphicElement}
      type="button"
      className={mergeClassNames('w-max px-6 bg-floor-green', className)}
    >
      {children}
    </BaseButton>
  ),
);
