// import { useEffect } from 'react';
// import Map, { Marker, MapProps } from 'react-map-gl';
// import useMedia from 'use-media';

// import Logo from '@means/assets/png/logo.png';

import styles from './styles.module.css';

// const hours = new Date().getHours();
// const isDay = hours > 6 && hours < 20;

// const mapStyleTheme = isDay
//   ? 'cl3na8aey004w15o4rigd4e0p' // style day
//   : 'cl3n95ue9003314pmq4307drl'; // style night
// const mapStyle = `mapbox://styles/hitechlinetecnologia/${mapStyleTheme}`;

// type MapSettings = Omit<MapProps, 'fog' | 'terrain'>;

// const defaultMapSettings: MapSettings = {
//   dragPan: false,
//   doubleClickZoom: false,
//   initialViewState: {
//     latitude: -19,
//     longitude: -50,
//     zoom: 4,
//     pitch: 90,
//   },
// };

// const states = [
//   { name: 'Rondônia', latitude: -11.5057, longitude: -63.5806 },
//   { name: 'Maranhão', latitude: -4.9609, longitude: -45.2744 },
//   { name: 'Pará', latitude: -1.9981, longitude: -54.9306 },
//   { name: 'Mato Grosso', latitude: -12.6819, longitude: -56.9211 },
//   { name: 'Góias', latitude: -15.9338, longitude: -50.1404 },
//   { name: 'Parana', latitude: -25.2521, longitude: -52.0215 },
//   { name: 'Minas Gerais', latitude: -18.5122, longitude: -44.555 },
//   { name: 'Amazonas', latitude: -3.4168, longitude: -65.8561 },
//   { name: 'Tocantins', latitude: -10.1753, longitude: -48.2982 },
//   { name: 'Pernambuco', latitude: -8.8137, longitude: -36.9541 },
//   { name: 'Paraíba', latitude: -7.24, longitude: -36.782 },
//   { name: 'Mato Grosso do Sul', latitude: -20.7722, longitude: -54.7852 },
// ];

export const MapWithBranches = (): JSX.Element => {
  // const [mapSettings, updateMapSettings] =
  //   useState<MapSettings>(defaultMapSettings);

  // const isTablet = useMedia({ maxWidth: '770px' });

  // useEffect(() => {
  //   if (isTablet) {
  //     const updateNewSettings = {
  //       ...defaultMapSettings,
  //       initialViewState: {
  //         latitude: -12.5,
  //         longitude: -50,
  //         zoom: 3,
  //         pitch: 0,
  //       },
  //     };

  //     updateMapSettings(updateNewSettings);

  //     return;
  //   }

  //   updateMapSettings(defaultMapSettings);
  // }, [isTablet]);

  return (
    <div className={styles.down}>
      {/* <Map
        {...mapSettings}
        {...mapSettings.initialViewState}
        style={{
          width: '100%',
          maxWidth: '100vw',
          height: '400px',
          borderRadius: '8px',
        }}
        mapStyle={mapStyle}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
      >
        {states.map(data => (
          <Marker
            key={data.name}
            longitude={data.longitude}
            latitude={data.latitude}
            anchor="bottom"
          >
            <img src={Logo.src} alt={data.name} className="w-16" />
          </Marker>
        ))}
      </Map> */}
    </div>
  );
};
