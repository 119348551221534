import { mergeRefs, mergeClassNames } from '@hitechline/reactools';
import NextImage from 'next/image';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import SwiperCore, { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import NavigationLeft from '@means/assets/svg/navigation-left.svg';
import NavigationRight from '@means/assets/svg/navigation-right.svg';
import { useBlogPosts } from '@modules/hooks/blog/useBlogPosts';
import { Link } from '@screen/components/forward/Link';
import { Button } from '@screen/components/ui/Button';

import { PostOnLoad } from './PostOnLoad';
import styles from './styles.module.css';

const SWIPER_OPTIONS: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 20,
  watchOverflow: true,
  resizeObserver: true,
  pagination: {
    dynamicBullets: true,
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
  },
};

export const PostsSlider = (): JSX.Element => {
  const swiperRef = useRef<SwiperCore>(null);

  const { loading, posts } = useBlogPosts(1);

  const [swiperDetails, updateSwiperDetails] = useState({
    canNext: false,
    canPrev: false,
  });

  const slides = useMemo(() => {
    if (loading || !posts.length) {
      return (
        <SwiperSlide>
          <PostOnLoad />
        </SwiperSlide>
      );
    }

    return posts.map(({ id, banner, title, description, uid }) => (
      <SwiperSlide key={id}>
        <div className="w-full">
          <div className="mx-auto max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
            <NextImage
              src={banner}
              width="1280"
              height="720"
              alt="Banner"
              className="rounded-3xl"
              objectFit="cover"
            />

            <p className="font-medium text-center text-1xl">{title}</p>

            <span className="mx-auto block my-6 h-px bg-floor-green w-2/5" />

            <p className="font-medium text-center text-md text-typography-grey">
              {description}
            </p>

            <Button
              asProp={Link}
              href={`/blog/p/${uid}`}
              className="flex items-center mt-8 mx-auto uppercase text-typography-white"
            >
              Leia mais
            </Button>
          </div>
        </div>
      </SwiperSlide>
    ));
  }, [loading, posts]);

  const goNext = useCallback(() => {
    swiperRef.current?.slideNext();
  }, []);

  const goPrev = useCallback(() => {
    swiperRef.current?.slidePrev();
  }, []);

  const updateSwiper = (): void => {
    if (!swiperRef.current) {
      return;
    }

    const { activeIndex, params, slides: swiperSlides } = swiperRef.current;
    const { length } = swiperSlides;
    const { slidesPerView } = params;

    const inIndex = activeIndex + (slidesPerView as number);

    updateSwiperDetails({
      canNext: inIndex < length,
      canPrev: activeIndex > 0,
    });
  };

  useEffect(() => {
    updateSwiper();
  }, [loading, posts]);

  useEffect(() => {
    const currentSwiperRef = swiperRef.current;

    currentSwiperRef?.on('slideChange', updateSwiper);

    return () => {
      currentSwiperRef?.off('slideChange', updateSwiper);
    };
  }, []);

  return (
    <div className="items-center flex">
      <button
        type="button"
        className={styles['navigation-button']}
        onClick={goPrev}
        disabled={!swiperDetails.canPrev}
      >
        <NavigationLeft className="w-14" />
      </button>

      <Swiper
        onSwiper={mergeRefs([swiperRef])}
        {...SWIPER_OPTIONS}
        className="spacing-x lg:px-0"
      >
        {slides}
      </Swiper>

      <button
        type="button"
        onClick={goNext}
        disabled={!swiperDetails.canNext}
        className={mergeClassNames(styles['navigation-button'], 'right')}
      >
        <NavigationRight className="w-14" />
      </button>
    </div>
  );
};
